import styled, { css } from 'styled-components';
import { media } from '../style/style-utils';

const TextsStyle = css`
  font-family: "Montserrat";
`;

export const P = styled.p`
  ${TextsStyle}

  font-size: 1.2em;
  font-weight: 400;
  margin: 10px 0;

  ${media.tablet`
    font-size: 1.1em;
  `}

  ${media.phone`
    font-size: 1em;
  `}
`;
