import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import { H1, H2, H3 } from '../components/Headings';
import { P } from '../components/Texts';

const Wrapper = styled.div`
  min-height: 700px;
  max-width: ${props => props.theme.contentWidth};
  padding: 15px;
  margin: auto;
`;

const StyledH1 = styled(H1)`
  text-align: center;
`;

const YouTubeIframeContainer = styled.div`
  margin: auto;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px; height: 0; overflow: hidden;
  height: 0;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const InfosPage = () => (
  <Layout>
    <Wrapper>
      <StyledH1>Informations</StyledH1>

      <br/>
      <br/>
      <div style={{ margin: 'auto', maxWidth: '800px' }}>
      <YouTubeIframeContainer>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/8rdDASMt8s8"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen>
        </iframe>
      </YouTubeIframeContainer>
      </div>

      <br/>
      <br/>

      <H2>You will love it</H2>

      <H3>Fits everyone</H3>

      <P>Astraliker brings attention to your profile by liking posts.</P>

      <H3>Simple</H3>

      <P>It takes less than 30 seconds to setup and run in background.</P>

      <H3>Secure</H3>

      <P>Astraliker do not need your Instagram username and password.</P>

      <H3>Time saver</H3>

      <P>By delegate liking your can save a huge amount of time.</P>

      <br/>
      <br/>
      <br/>
      <br/>

      <H2>Setup Astraliker</H2>

      <P>1. Download Astraliker Chrome Extension.</P>

      <P>2. Click on the Astraliker icon at the to right of your browser to open app's window.</P>

      <P>3. Go to "Tasks" menu.</P>

      <P>4. Setup your first task by clicking "Add" at the top right of the window.</P>

      <P>5. That's is. Sit back and relax while your account grow.</P>

      <br/>
      <br/>
      <br/>
      <br/>

      <H2>Frequently asked questions</H2>

      <H3>Can it auto-follow ?</H3>

      <P>No, auto-follow is spammy.<br/>
      This practice can be detected by Instagram and your account can get banned.</P>

      <H3>Can it auto-comment?</H3>

      <P>No, auto-comment is spammy and most of the time it annoys people.</P>

      <H3>What if my computer is shut down ?</H3>

      <P>Astraliker works when Google Chrome is open, when your computer is shut down, Astraliker will stop.</P>

      <br/>
      <br/>
      <br/>
      <br/>

      <H2>Contact us</H2>

      <P>Send us an email at: support@astraliker.com</P>

      <br/>
      <br/>
      <br/>
      <br/>

    </Wrapper>
  </Layout>
);

export default InfosPage;
